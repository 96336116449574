
require.register("component-inherit/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "component-inherit");
  (function() {
    module.exports = function(a, b){
  var fn = function(){};
  fn.prototype = b.prototype;
  a.prototype = new fn;
  a.prototype.constructor = a;
};
  })();
});