
require.register("socket.io-parser/is-buffer.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "socket.io-parser");
  (function() {
    module.exports = isBuf;

/**
 * Returns true if obj is a buffer or an arraybuffer.
 *
 * @api private
 */

function isBuf(obj) {
  return (global.Buffer && global.Buffer.isBuffer(obj)) ||
         (global.ArrayBuffer && (obj instanceof ArrayBuffer || ArrayBuffer.isView(obj)));
}
  })();
});