
require.register("indexof/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "indexof");
  (function() {
    var indexOf = [].indexOf;

module.exports = function(arr, obj){
  if (indexOf) return arr.indexOf(obj);
  for (var i = 0; i < arr.length; ++i) {
    if (arr[i] === obj) return i;
  }
  return -1;
};
  })();
});