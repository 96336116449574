
require.register("engine.io-parser/lib/keys.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "engine.io-parser");
  (function() {
    /**
 * Gets the keys for an object.
 *
 * @return {Array} keys
 * @api private
 */

module.exports = Object.keys || function keys (obj){
  var arr = [];
  var has = Object.prototype.hasOwnProperty;

  for (var i in obj) {
    if (has.call(obj, i)) {
      arr.push(i);
    }
  }
  return arr;
};
  })();
});