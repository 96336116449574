
require.register("socket.io-client/lib/on.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "socket.io-client");
  (function() {
    /**
 * Module exports.
 */

module.exports = on;

/**
 * Helper for subscriptions.
 *
 * @param {Object|EventEmitter} obj with `Emitter` mixin or `EventEmitter`
 * @param {String} event name
 * @param {Function} callback
 * @api public
 */

function on (obj, ev, fn) {
  obj.on(ev, fn);
  return {
    destroy: function () {
      obj.removeListener(ev, fn);
    }
  };
}
  })();
});