
require.register("engine.io-client/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {"ws":false,"xmlhttprequest-ssl":"engine.io-client/lib/xmlhttprequest"}, "engine.io-client");
  (function() {
    module.exports = require('./socket');

/**
 * Exports parser
 *
 * @api public
 *
 */
module.exports.parser = require('engine.io-parser');
  })();
});